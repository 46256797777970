import { createResource, Show, createSignal } from "solid-js";
import { createStore } from "solid-js/store";
import { useSearchParams, useNavigate } from "@solidjs/router";

import Button from "~/components/Button";
import { TextFieldInput } from "~/components/inputs";
import { Loader } from "~/components/utility";
import Body from "~/components/Body";

export default function ForgotPassword() {
  const [params, setParams] = useSearchParams<{
    email?: string;
    code?: string;
  }>();
  const [count, setCount] = createSignal(10);
  const [resetSuccess, setResetSuccess] = createSignal(false);
  const navigate = useNavigate();
  let timerId: ReturnType<typeof setInterval>;
  const [form, setForm] = createStore<{
    [key: string]: string | boolean | null | undefined;
  }>({
    email: params.email || "",
    code: params.code || null,
    password: "",
    confirm_password: "",
    send: false,
    error: null,
    success: null,
  });
  const [reset] = createResource(
    () => form.send,
    async () => {
      if (form.email == "") {
        setForm("error", "Please enter an email address to continue.");
        return;
      }
      if (form.code != null && form.password !== form.confirm_password) {
        setForm("error", "Please ensure that your passwords match.");
        return;
      }
      setForm("error", null);
      const response = await fetch(
        `${import.meta.env.VITE_ROMA_API}/account/${
          params.code ? "reset/confirm" : "reset/request"
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(
            params.code
              ? {
                  Code: params.code,
                  Password: form.password,
                  Username: form.email,
                }
              : {
                  Username: form.email,
                }
          ),
        }
      );
      setForm("send", false);
      if (!response.ok) {
        const data = await response.json();
        setForm(
          "error",
          "There was an unexpected error, kindly contact customer service for additional support."
        );
        return false;
      }
      if (!params.code) {
        setForm(
          "success",
          "Please check your email for additional instructions regarding how to reset your password."
        );
      } else {
        setForm("success", "Your password has sucessfully been reset.");
        setResetSuccess(true);
        setParams({ code: undefined, email: undefined, reset: undefined });
        timerId = setInterval(countdown, 1000);
      }
      return true;
    },
    {
      initialValue: false,
      ssrLoadFrom: "initial",
    }
  );

  const countdown = () => {
    if (count() > 0) {
      setCount(count() - 1);
    } else {
      navigate("/?signIn=true");
      clearInterval(timerId);
    }
  };

  return (
    <Body class="py-20 space-y-10">
      <h1 class="flex w-full text-4xl justify-center">Forgot Password</h1>
      <div class="max-w-[500px] mx-auto">
        <form
          class="space-y-3"
          onSubmit={(evt) => {
            evt.preventDefault();
            setForm("send", true);
          }}
        >
          <div class="mb-5">
            If you have forgotten your Roma customer password, enter your email
            address below to have a reset request sent to your email.
          </div>
          <Show when={form.success}>
            <div class="bg-roma-grey text-roma-dark-grey text-sm p-5">
              {form.success}{" "}
              <Show when={resetSuccess()}>
                You will be redirected to the homepage in {count()} seconds, or{" "}
                <button
                  type="button"
                  class="text-roma-blue cursor-pointer"
                  onClick={() => {
                    navigate("/?signIn=true");
                    clearInterval(timerId);
                  }}
                >
                  sign in now
                </button>
              </Show>
            </div>
          </Show>
          <Show when={!form.success}>
            <Show when={form.error}>
              <div class="border-2 border-red-500 text-red-500 p-3 rounded-md">
                {form.error}
              </div>
            </Show>
            <TextFieldInput
              type="email"
              name="email"
              label="Email Address"
              onChange={(val) => setForm("email", val)}
              value={form.email as string}
              disabled={!!params.code}
            />
            <Show when={params.code}>
              <TextFieldInput
                label="Password"
                onChange={(val) => setForm("password", val)}
                name="password"
                type="password"
                required={true}
              />
              <TextFieldInput
                label="Confirm Password"
                onChange={(val) => setForm("confirm_password", val)}
                name="confirm_password"
                type="password"
                required={true}
              />
            </Show>
            <Button
              tabIndex="4"
              type="submit"
              class="flex mt-5 gap-2 relative w-full text-base"
              disabled={reset.loading}
            >
              <div>Reset Password</div>
              <Loader show={reset.loading} height="5" width="5" />
            </Button>
          </Show>
        </form>
      </div>
    </Body>
  );
}
